<template>
  <div></div>
</template>

<script>
export default {
  name: 'ProgressUnit',
  mounted () {
    this.redirect()
  },
  methods: {
    redirect () {
      if (this.isRekanan) {
        this.$router.push({ name: 'proyek.progressUnit.asKontraktor' })
      } else {
        this.$router.push({ name: 'proyek.progressUnit.asTeknik' })
      }
    }
  }
}
</script>
